
import Container from 'react-bootstrap/Container';


export default function Guest({ children }) {
    return (
       <div className="vw-100 vh-100 d-flex justify-content-center align-items-center flex-column">
            <img src="/images/logos/ses.png"  alt="Scientific Education Support" style={{display:'block',width: '10rem',margin:'0 auto'}}/>
            <div style={{width:'30rem',maxWidth:'90%'}} className="d-flex flex-column align-items-center">
                {children}
            </div>
        </div>
    );
}
